/* main Container */
.unitdetails-container {
  width: 90%;
  margin: auto;
}

/* First section */

.unitdetails-firstSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 3rem;
}
/* First section search  */
.unitdetails-searchDiv {
  width: 50%;
  padding: 8px 15px;
  border: 2px solid #1c1b4d;
  border-radius: 31px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.searchIcon {
  width: 24px;
  height: 24px;
  margin-right: 20px;
}

.unitdetails-searchInput {
  width: 100%;
  /* padding: 8px 15px; */
  border: none;
  box-sizing: border-box;
  /* border-radius: 31px; */
  background-color: inherit;
  outline: none;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 16px;
  color: #1c1b4d;
}

#searchInput:before {
  content: url('../../images/searchIcon.svg');
  padding-right: 10px;
}

.unitdetails-searchInput::placeholder {
  color: #1c1b4d;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 16px;
  opacity: 0.5;
}

/* First section Add unitdetails  */
.unitdetails-addloanDiv {
  width: 20%;
}

.unitdetails-addBtn {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  outline: none;
  background: #1c1b4d;
  border: none;
  border-radius: 31px;
  padding: 8px 10px;
  color: #fff;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
}

.unitdetails-addBtn span {
  margin-left: 10px;
}
