.loginPage-container {
  width: 100%;
}

.loginPage-formDiv {
  background-color: #1c1b4d;
  width: 80%;
  margin: auto;
  margin-top: 5%;
  border: 1px solid #cdcdcd;
  border-radius: 8px;
}
@media only screen and (min-width: 600px) {
  .loginPage-formDiv {
    width: 60%;
  }
}
@media only screen and (min-width: 768px) {
  .loginPage-formDiv {
    width: 45%;
  }
}
@media only screen and (min-width: 992px) {
  .loginPage-formDiv {
    width: 30%;
  }
}
.loginPage-formHeaderDiv {
  text-align: center;
  margin: 2rem auto;
}

.logoContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.logoImage {
  height: 100px;
  object-fit: cover;
  margin-bottom: 10px;
}

.brandName {
  font-weight: 600;
  font-size: 19px;
  text-align: center;
  color: #f47e20;
  opacity: 0.7;
  letter-spacing: 0.4px;
}

.loginPage-headerTitle {
  font-weight: 600;
  font-size: 24px;
  text-align: center;
  letter-spacing: 0.3px;
  margin-bottom: 10px;
  color: #9fa2b4;
}
.loginPage-headerSub {
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.3px;
  color: #9fa2b4;
}

.loginPage-formContent {
  width: 80%;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 3rem;
}

.loginPage-formFieldDiv {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 20px;
}

.loginPage-inputLabel {
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: #9fa2b4;
  margin: 0px 5px;
  margin-bottom: 3px;
}

.forgotPass {
  font-size: 10px;
  text-transform: capitalize;
}

.forgotPass:hover {
  color: #f47e20;
  text-decoration-line: underline;
}

.loginPage-inputField {
  width: 100%;
  padding: 14px;
  background: #fcfdfe;
  border: 1px solid #f0f1f7;
  box-sizing: border-box;
  border-radius: 8px;
  outline: none;
}

.loginPage-inputField::placeholder {
  font-size: 14px;
  letter-spacing: 0.3px;
  color: #4b506d;
  opacity: 0.4;
}

.loginPage-passDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.loginPage-submitBtnDiv {
  width: 100%;
  margin: auto;
  margin-top: 1rem;
}

.loginPage-submitBtn {
  width: 100%;
  background: #f47e20;
  box-shadow: 0px 4px 12px rgba(55, 81, 255, 0.24);
  border: none;
  border-radius: 8px;
  padding: 15px;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.2px;
  cursor: pointer;
}
