/* Main contianer */
.addproject-container {
  width: 90%;
  margin: auto;
}

/* For validation */
#red-border {
  border: 1px solid red;
}

/* form details container */
.addproject-personalDetails {
  width: 70%;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 3rem;
}

/* form row */
.addproject-alignRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

/* Input Filed div */
.addproject-inputFieldDiv {
  width: 46%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 1.5rem;
}

/* Input field Label */
.addproject-inputLabel {
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.3px;
  color: #9fa2b4;
  margin: 0px 5px;
  margin-bottom: 1px;
}

/* Input field Input*/
.addproject-inputField {
  border: 0.5px solid #1c1b4d;
  box-sizing: border-box;
  width: 100%;
  border-radius: 5px;
  font-family: Montserrat;
  font-weight: 400;
  font-size: 16px;
  color: #a4a5a9;
  background-color: transparent;
  padding: 13px 15px;
  outline: none;
}
.addproject-inputFieldDiv-image {
  padding-top: 15px;
  width: 100%;
  text-align: center;
}

/* Text Area Input Filed div */
.addproject-textFieldDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 1.5rem;
}

/* Text Area Input Filed*/
.addproject-textField {
  border: 0.5px solid #1c1b4d;
  box-sizing: border-box;
  width: 100%;
  min-height: 200px;
  border-radius: 5px;
  font-family: Montserrat;
  font-weight: 400;
  font-size: 16px;
  color: #a4a5a9;
  background-color: transparent;
  padding: 13px 15px;
  outline: none;
}

/* Submit btn div */

.addproject-submitDetailDiv {
  width: 40%;
  margin: auto;
  margin-top: 3rem;
}
/* Submit btn Detail */
.addproject-submitDetailBtn {
  width: 100%;
  border: none;
  outline: none;
  background: #1c1b4d;
  padding: 15px;
  box-shadow: 0px 4px 12px rgba(55, 81, 255, 0.24);
  border-radius: 8px;
  font-family: Mulish;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #ffffff;
  cursor: pointer;
}
